import { render, staticRenderFns } from "./SelfEmployed.vue?vue&type=template&id=0b95f374&scoped=true"
import script from "./SelfEmployed.vue?vue&type=script&lang=js"
export * from "./SelfEmployed.vue?vue&type=script&lang=js"
import style0 from "./SelfEmployed.vue?vue&type=style&index=0&id=0b95f374&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b95f374",
  null
  
)

export default component.exports